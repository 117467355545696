.advice {
    padding-bottom: 150px;
    @include media("<=430px") {
        padding-bottom: 100px;
    }
}

.advice__inner {
    position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        max-width: 960px;
        margin: 0 auto;
        padding: 0 45px 45px;
        width: 100%;
        z-index: 2;
        background: #fee0df40;
        text-align: center;
        @include media("<=810px") {
            padding: 0 20px 30px;
        }
        @include media("<=430px") {
            padding: 0 15px 30px;
        }
}

.advice__image {
    max-width: 262px;
    margin-bottom: 40px;
    margin-top: -130px;
    @include media("<=430px") {
        max-width: 180px;
        margin-top: -90px;
        margin-bottom: 30px;
    }
    img {
        width: 100%;
        display: block;
    }
}

.advice__text {
    padding-top: 20px;
    font-size: 22px;
    line-height: 1.7;
    max-width: 764px;
    @include media("<=430px") {
        font-size: 18px;
    }
}