.consist {
 background-color: #E3382B;
 padding: 114px 0;
@include media("<=1080px") {
    padding: 80px 0;
}
@include media("<=810px") {
    padding: 60px 0;
}
}

.consist__inner {
    display: flex;
    column-gap: 118px;
    color: #ffffff;
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
    }
}

.consist__column {
    display: flex;
    flex-direction: column;
    max-width: 470px;
    flex: 0 1 100%;
}

.consist__image {
    max-width: 470px;
    margin-bottom: 40px;
    @include media("<=810px") {
        margin-bottom: 20px;
    }
    img {
        width: 100%;
        display: block;
    }
}

.consist__title {
    font-size: 72px;
    font-weight: 800;
    margin-bottom: 30px;
    @include media("<=810px") {
        font-size: 64px;
    }
        @include media("<=430px") {
         font-size: 40px;
        }
}

.consist__text {
    font-size: 24px;
    line-height: 1.3;
    @include media("<=810px") {
        font-size: 20px;
    }
}




