.info {
    padding: 100px 0;
    overflow: hidden;
    background: #EEFDE2;
    @include media("<=1080px") {
        padding: 80px 0;
    }
    @include media("<=430px") {
        padding: 60px 0;
    }
    .section__title {
        text-align: center;
    }
}

.info__subtitle {
    text-align: center;
    padding: 30px 0 90px;
    font-size: 36px;
    @include media("<=810px") {
        font-size: 30px;
    }
        @include media("<=430px") {
        font-size: 24px;
        }
}

.info__inner {
    position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        column-gap: 20px;
        row-gap: 50px;
       flex-wrap: wrap;
    @include media("<=650px") {
      align-items: center;
      flex-direction: column;
      row-gap: 30px;
        
    }
   
}

.info__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 0 1 32%;
    opacity: 0;
        transition: all 0.8s ease 0.2s;
    @include media("<=1080px") {
        flex: 0 1 31%;
    }
        @include media("<=810px") {
            flex: 0 1 48%;
        }

        &._active {
            opacity: 1;
        }
    &:nth-of-type(2) {
    transition: all 0.8s ease 0.4s;
    }  
 &:nth-of-type(3) {
    transition: all 0.8s ease 0.6s;
 }
 &:nth-of-type(4) {
    transition: all 0.8s ease 0.8s;
 }
 &:nth-of-type(5) {
    transition: all 0.8s ease 1s;
 }
}

.info__icon {
    margin-bottom: 20px;
    @include media("<=430px") {   
        margin-bottom: 10px;
    }
    img {
    }
}

.info__text {
    font-size: 24px;
    line-height: 1.3;
    max-width: 372px;
    @include media("<=650px") {
        font-size: 20px;
    }
}








