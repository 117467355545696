.header {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  padding: 50px 0;
  @include media("<=1280px") {
    padding: 36px 0;
  }
  @include media("<=810px") {
    padding: 24px;
  }
  @include media("<=450px") {
    padding: 20px 0 ;
  }
  &.page {
    position: relative;
  }
}

.header__inner {
  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 20;
  column-gap: 15px;
  @include media("<=810px") {
    column-gap: 0;
  }
}

.header__nav {
  flex: 0 1 100%;
  width: 100%;
  max-width: 663px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media("<=1080px") {
    max-width: 520px;
  }
  @include media("<=810px") {
    display: none;
    max-width: 100%;
    &.active {
      display: flex;
      position: fixed;
      height: 50%;
      width: 100%;
      max-width: none;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: #E3382B;
      z-index: 20;
      margin-right: 0;
      margin-left: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: space-between;
      align-items: center;
      opacity: 1;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      @include media("<=430px") {
        padding-bottom: 0;
        
      }
      .header__link {
        padding: 20px;
        @include media("<=430px") {
          padding: 15px;
        }
        @include media("<=350px") {
          padding: 10px;
        }
      }
    }
  }
}

.header__link {
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  color: #000000;
  transition: all 0.3s ease;
  position: relative;
  @include media("<=810px") {
    color: #ffffff;
  }

  &:hover {
    color: #E3382B;
  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1em;
  background: #000000;
  bottom: -6px;
  left: 0;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}

.header__tel {
  position: relative;
 
  @include media("<=1080px") {
   
  }
    @include media("<=810px") {
     
    }
  
}

.header__tel-link {
  font-size: 24px;
  font-weight: 600;
    line-height: 1.2;
    text-decoration: none;
  color: #000000;
  transition: all 0.3s ease;
    position: relative;
    display: block;
    padding-left: 50px;
    @include media("<=430px") {
      font-size: 18px;
      padding-left: 35px;
    }
    &:hover {
        color: #E3382B;
        text-decoration: underline;
      }
    &::before {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-image: url(../images/header-tel.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include media("<=430px") {
        width: 25px;
        height: 25px;
      }
    }
}

.marquee {
  padding: 8px 0;
  width: 100%;
  background: #E3382B;
  display: flex;
  column-gap: 180px;
  color: #ffffff;
}

.marquee__text {
  display: flex;
  column-gap: 180px;
  font-size: 18px;
  font-weight: 600;
}



