.footer {
background: #E3382B;
  padding: 50px 0;
  overflow: hidden;
  position: relative;
  @include media("<=810px") {
    padding: 30px 0;
    
  }
    @include media("<=430px") {
      padding: 15px 0 30px;
    }
    &-page {
      background: transparent;
    }
}

.footer__inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include media("<=1080px") {

  }
  @include media("<=810px") {

  }
  @include media("<=430px") {
    
  }
}


.footer__link {
  font-size: 20px;
  font-weight: 500;
  color: #Ffffff;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  
  @include media("<=430px") {
    font-size: 18px;
   
  }
@include media("<=350px") {
 
}
  &:hover {
    text-decoration: underline;
  }
  &-page {
    color: #000000;
  }
}



