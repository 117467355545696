.page {
  padding: 120px 0 60px;
  @include media("<=430px") {
    padding: 90px 0 40px;
  }
}

.page__inner {
  padding-top: 60px;
  position: relative;
  z-index: 3;
  color: #000000;
  text-align: justify;
  @include media("<=810px") {
    padding-top: 50px;
    
  }
}

.page__text {
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 20px;
  line-height: 1.2;
  @include media("<=430px") {
    font-size: 16px;
    padding-bottom: 15px;
  }
  b {
    font-weight: 700;
  }
}

