.hero {
  position: relative;
  z-index: 2;
  overflow-x: clip;
  margin: 0 auto;
  padding: 208px 0 150px;
  background: linear-gradient(90deg, #eefde2 50%, #FFFFFF 50%);
  @include media("<=1280px") {
    padding: 180px 0 130px;
  }
  @include media("<=810px") {
    background: #eefde2;
    padding-top: 100px;
    padding-bottom: 60px;
  }
  @include media("<=430px") {
    padding: 80px 0 40px;
  }
  
}

.hero__inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding-top: 28vw;
  }
  @include media("<=600px") {
    padding-top: 48vw;
  }
  @include media("<=430px") {
    padding-top: 52vw;
  }
  @include media("<=320px") {
  }
}

.hero__content {
  flex: 0 1 100%;
  max-width: 571px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  @include media("<=1080px") {
    max-width: 505px;
    
  }
  @include media("<=810px") {
    align-items: center;
    
    max-width: 600px;
  }
    @include media("<=430px") {
      
    }
}

.hero__wrapper {
  @include media("<=810px") {
    position: absolute;
    width: 100%;
    top: 0;
    max-width: 590px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.hero__subtitle {
    font-size: 24px;
    line-height: 0.9;
    margin-bottom: 30px;
    font-weight: 800;
    @include media("<=810px") {
      
    }
    @include media("<=500px") {
      font-size: 4.5vw;
    }
}

.hero__title {
    color: #E3382B;
    font-size: 90px;
    font-weight: 800;
    line-height: 0.9;
  width: 100%;
  margin-bottom: 20px;
  opacity: 0;
  transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
  @include media("<=1280px") {
    font-size: 64px;
  }

  @include media("<=1080px") {
    font-size: 48px;
    margin-bottom: 20px;
  }
  @include media("<=810px") {
    max-width: 505px;
    
    font-size: 6.5vw;
  }
  @include media("<=430px") {
    font-size: 9vw;
  }

  @include media("<=320px") {
  }
  img {
    width: 100%;
    display: block;
   
  }
}

.hero__text {
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 30px;
  @include media("<=810px") {
    font-size: 20px;
  }
    @include media("<=430px") {
      font-size: 16px;
    }
}

.hero__image {
  flex: 0 1 100%;
  max-width: 487px;
  position: relative;
opacity: 0;
  transition: all 0.8s ease 0.6s;

  &._active {
    opacity: 1;
  }
  @include media("<=1080px") {
    max-width: 420px;
    
  }
  @include media("<=810px") {
    margin-bottom: 30px;
    max-width: 400px;
    left: 2%;
  }
  @include media("<=500px") {
    max-width: 280px;
  }
    @include media("<=430px") {
     margin-bottom: 15px;
    }
  img {
    display: block;
    position: relative;
    width: 100%;
  }
    
}

.hero__price {
  margin-bottom: 20px;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;
    @include media("<=1080px") {
      font-size: 30px;
      margin-bottom: 40px;
    }
        @include media("<=810px") {
          text-align: center;
        }
    @include media("<=430px") {
      font-size: 20px;
      margin-bottom: 30px;
    }
    span {
      font-weight: 400;
      text-decoration: line-through;
      display: block;
    }
}

.button {
  max-width: 300px;
  width: 100%;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  font-weight: 700;
  padding: 32px 43px;
  border-radius: 50%;
  background: #E3382B;
  border: 9px solid #eefde2;
    outline: 1px solid #E3382B;
  transition: all 0.3s linear;

  &:hover {
    background-color: #eefde2;
    color: #E3382B;
    border: 9px solid #E3382B;
  }

  @include media("<=810px") {
    margin: 0 auto;
  }

  @include media("<=430px") {
    font-size: 18px;
    padding: 28px;
    max-width: 260px;
  }
}

.phone {
  background-color: #E3382B;
  padding: 42px 0;
}

.phone__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.phone__text {
  font-size: 36px;
  margin-bottom: 19px;
  color: #FFFFFF;
  @include media("<=550px") {
    font-size: 28px;
  }
}

.phone__link {
  color: #FFFFFF;
  transition: all 0.3s ease;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 72px;
    font-weight: 700;
    text-decoration: none;
    column-gap: 20px;
    @include media("<=810px") {
      font-size: 64px;
    }
        @include media("<=550px") {
          font-size: 40px;
          column-gap: 10px;
        }
    &:hover {
      text-decoration: underline;
    }
    &::before {
        content: '';
        width: 97px;
        height: 97px;
        display: block;
        background-image: url(../images/phone.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    
        @include media("<=430px") {
          width: 40px;
          height: 40px;
        }
      }
}








