.contacts {
    position: relative;
    overflow-x: clip;
    padding: 30px 0;
    background: #E3382B;
    @include media("<=810px") {
       padding-bottom: 60px;
    }
        @include media("<=430px") {
            padding-bottom: 40px;
        }
  
}

.contacts__inner {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
@include media("<=1080px") {
    
}
    @include media("<=810px") {
       flex-direction: column;
        
    }

    @include media("<=430px") {
        
    }
}

.contacts__content {
    flex: 0 1 100%;
    max-width: 661px;
    color: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media("<=1080px") {
        max-width: 560px;
    }
 @include media("<=810px") {
    display: flex;
     flex-direction: column;
     align-items: center;
    text-align: center;
    width: 100%;
 }
}

.contacts__title {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 36px;
    @include media("<=1080px") {

    }
  @include media("<=430px") {
    font-size: 36px;
    margin-bottom: 20px;
  }
}

.contacts__text {
    font-size: 24px;
    line-height: 1.2;
    margin-top: 37px;
    @include media("<=1280px") {
        
       
    }
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
    
    text-align: center;
  }
  @include media("<=430px") {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
}

.contacts__phone {
   
}

.contacts__link {
    color: #ffffff;
    font-weight: 700;
    font-size: 72px;
    text-decoration: none;
    @include media("<=430px") {
        font-size: 40px;
    }
    &:hover {
        text-decoration: underline;
    }
}

.contacts__image {
    flex: 0 1 100%;
    position: relative;
    max-width: 406px;
    opacity: 0;
    transition: all 0.8s ease 0.2s;
    @include media("<=810px") {
        max-width: 350px;
    }
         @include media("<=430px") {
            max-width: 260px;
         }
        &._active {
            opacity: 1;
        }
    img {
        width: 100%;
        display: block;
    }
}


