body {
  margin: 0;
  font-family: 'Playfair Display', serif;
  background: #ffffff;
  color: #000000;
  position: relative;
  @include media("<=810px") {
    background-position-x: 20%;
  }
  @include media("<=430px") {
    
  }
  
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}

.section__title {
  font-size: 72px;
  color: #E3382B;
  font-weight: 800;
  line-height: 1.3;
  
  @include media("<=1280px") {
    font-size: 64px;
  }
  @include media("<=1080px") {
    font-size: 52px;
  }
  @include media("<=810px") {
    font-size: 48px;
    
    
  }
  @include media("<=430px") {
    font-size: 32px;
    
  }
  
}

//BURGER BUTTON//
.burger__button {
  display: none;

  @include media("<=810px") {
    display: block;
    position: fixed;
    top: 56px;
    right: 40px;
    z-index: 200;
  }

  @include media("<=430px") {
    top: 52px;
    right: 20px;
  }

  @include media("<=810px") {
    &.active {
      position: fixed;
      top: 72px;
      right: 40px;
      z-index: 200;
    }
    @include media("<=430px") {
      &.active {
        top: 70px;
        right: 20px;
      }
    }
  }

  .line {
    height: 6px;
    width: 58px;
    margin-bottom: 8px;
    background-color: #000000;
    transition: all 200ms ease-in-out;
    opacity: 1;

    @include media("<=736px") {
      height: 4px;
      width: 48px;
    }
  }

  &.active {
    .line {
      background-color: #ffffff;
      &.one {
        transform: rotate(45deg) scale(1.2);
      }
      &.two {
        transform: rotate(-45deg) scale(1.2) translateY(-8px) translateX(8px);
        @include media("<=736px") {
          transform: rotate(-45deg) scale(1.2) translateY(-7px) translateX(7px);
        }
      }
      &.three {
        opacity: 0;
      }
    }
  }
}
