.features {
  
  padding: 130px 0;
  overflow: hidden;
  @include media("<=960px") {
    padding: 80px 0;
  }
    @include media("<=430px") {
     padding: 60px 0;
    }
  
}

.features__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  margin: 0 auto;
  @include media("<=810px") {
    flex-direction: column-reverse;
  }
}

.features__image {
  max-width: 410px;
  flex: 0 1 100%;
  @include media("<=1080px") {
    max-width: 400px;
  }
  @include media("<=430px") {
    max-width: 260px;
    margin-bottom: 10px;
  }
  img {
    width: 100%;
    display: block;
  }
}

.features__content {
  flex: 0 1 100%;
  max-width: 639px;
  display: flex;
  flex-direction: column;
  @include media("<=810px") {
    max-width: 600px;
    align-items: center;
    margin-bottom: 30px;
  }
}

.features__list {
  padding-top: 50px;
  padding-left: 20px;
  max-width: 605px;
  list-style-type: disc;
  @include media("<=810px") {
    width: 100%;
  }
}

.list__item {
  font-size: 24px;
  line-height: 1.5;
  opacity: 0;
    transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
  @include media("<=1080px") {
    font-size: 20px;
  }
  
  @include media("<=810px") {
    
  }
  @include media("<=550px") {
    font-size: 18px;
  }
    &:nth-of-type(2) {
      transition: all 0.8s ease 0.4s;
    }
  
    &:nth-of-type(3) {
      transition: all 0.8s ease 0.6s;
    }
  
    &:nth-of-type(4) {
      transition: all 0.8s ease 0.8s;
    }
        &:nth-of-type(5) {
          transition: all 0.8s ease 1s;
        }
         &:nth-of-type(6) {
           transition: all 0.8s ease 1.2s;
         }
        &:nth-of-type(7) {
          transition: all 0.8s ease 1.4s;
        }
    &:nth-of-type(8) {
      transition: all 0.8s ease 1.6s;
    }
     &:nth-of-type(9) {
       transition: all 0.8s ease 1.8s;
     }
  
     &:nth-of-type(10) {
       transition: all 0.8s ease 2s;
     }
  
     &:nth-of-type(11) {
       transition: all 0.8s ease 2.2s;
     }
  
     &:nth-of-type(12) {
       transition: all 0.8s ease 2.4s;
     }
}

