.about {
  position: relative;
  padding: 130px 0;
@include media("<=1080px") {
  padding: 100px 0;
}
  @include media("<=960px") {
    padding-top: 80px;
  }
  @include media("<=430px") {
    padding: 60px 0;
  }
    
}

.about__inner {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 20px;
  width: 100%;
  z-index: 2;
  
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    height: auto;
  }
  @include media("<=430px") {
    row-gap: 20px;
    
  }
  &-bottom {
    @include media("<=810px") {
      flex-direction: column;
    }
  }   
}

.about__content {
  max-width: 549px;
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include media("<=1080px") {
    
  }
    @include media("<=810px") {
    
    }
}

.about__title {
  color: #E3382B;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.2;
@include media("<=430px") {
  font-size: 20px;
}
}

.about__image {
  flex: 0 1 100%;
  max-width: 568px;
  @include media("<=1080px") {
    max-width: 450px;
  }
  @include media("<=810px") {
    max-width: 560px;
  }
    @include media("<=430px") {
      
    }
  img {
    width: 100%;
    display: block;
  }
  &-2 {
    
  }
}


.about__text {
  
  flex: 0 1 100%;
  font-size: 24px;
  line-height: 1.4;
  transition: all 0.8s ease-in;
  opacity: 0;
  &._active {
    opacity: 1;
  }
    @include media("<=1080px") {
      font-size: 20px;
      line-height: 1.2;
    }
  @include media("<=810px") {
   max-width: 100%;
   
  }
    @include media("<=430px") {
      font-size: 18px;
    }
    p {
      padding-top: 30px;
      @include media("<=430px") {
        padding-top: 20px;
      }
    }
}






